<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <v-select
                  v-model="filters.status"
                  :items="statuses"
                  label="Статус"
                  outlined hide-details
                ></v-select>
              </v-col>
              <v-col cols="3"></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="slots"
                  :items-per-page="50"
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Слоты</v-toolbar-title>
                      <v-divider
                        class="mx-4"
                        inset
                        vertical
                      ></v-divider>
                      <v-spacer></v-spacer>
                      <create-slots></create-slots>
                    </v-toolbar>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <edit-slot-dialog v-model="editItem" :dialog.sync="editDialog" @close="editDialog=false"></edit-slot-dialog>
  </v-container>
</template>

<script>
import { ref, computed } from "@vue/composition-api"
import store from "@/store"
import EditSlotDialog from "@/views/ads/slots/EditSlotDialog"
import CreateSlots from "@/views/ads/slots/CreateSlots"
import AdPostSelector from "@/components/AdPostSelector";

export default {
  name: "AdSlots",
  components:{
    AdPostSelector,
    EditSlotDialog, CreateSlots,
  },
  setup() {
    const defaultItem = ref({id: 0, update_publication: 0})
    const editItem = ref({id: 0, update_publication: 0})
    const editDialog = ref(false)
    const headers = ref([
      { text: "Канал", value: "channel.name"},
      { text: "Источник", value: "adChannel.name" },
      { text: 'Пост', value: 'adPost.name'},
      { text: "Стоимость", value: "price" },
      {text: 'Статус', value: 'status'},
      {text: 'Дата', value: 'planned_at'},
    ])
    const filters = ref({
      channel_id: null,
      ad_channel_id: null,
      status: null,
    })

    const statuses = computed(()=>store.getters.adSlotStatuses)
    const allSlots = computed(()=>store.getters.adSlots)
    const slots = computed(()=>{
      let s = allSlots.value
      if (filters.value.channel_id) s = s.filter(x => x.channel_id === filters.value.channel_id)
      if (filters.value.ad_channel_id) s = s.filter(x => x.ad_channel_id === filters.value.ad_channel_id)
      if (filters.value.status) s = s.filter(x => x.status === filters.value.status)
      return s
    })


    const createSlot = ()=>{
      editItem.value = defaultItem.value
      editDialog.value = true
    }
    return {
      statuses,
      filters,
      slots, headers,
      editItem, editDialog,
      createSlot,
    }
  },
}
</script>

<style scoped>

</style>
